import firebase from 'gatsby-plugin-firebase'
import { pushToGtmDataLayer } from '/src/functions/gtm'
import { sendToF24 } from '/src/functions/f24'
import { navigate } from 'gatsby'
//import * as Sentry from '@sentry/gatsby'

const sha256 = require('sha256')
const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
    firebase
        .firestore()
        .settings({ experimentalForceLongPolling: true, merge: true })
}

const submitLead = async (options) => {
    let formSuccess = false

    const sendToOfficeLine = async (options) => {
        try {
            // Sending to Office Line...
            const response = await fetch(
                `https://pline.uk/web/selfgenleadsubmit`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: options.olData
                }
            )

            if (response.ok) {
                // All ok sending to Office Line, proceed as normal
                options.actions.setSubmitting(false)

                const data = await response.json()

                if (data) {
                    // Save data to session storage
                    sessionStorage.setItem('leadId', data.lead.id)
                    // Veriform
                    sessionStorage.setItem(
                        'sourceId',
                        options.veriform.sourceId
                    )
                    sessionStorage.setItem('crCid', options.veriform.crCid)

                    // Sending to GTM...
                    await pushToGtmDataLayer([
                        ['event', 'lead_form_validated'],
                        ['lead_id', data.lead.id],
                        ['ctq_email_address_hash', sha256(options.values.email)]
                    ])

                    // Sending to F24...
                    sendToF24(options, data.lead.id)

                    formSuccess = true
                }
            } else {
                // Sentry.captureMessage(
                //     `Could not send to Office Line: ${response.status}`,
                //     'error'
                // )
                console.error(
                    `Could not send to Office Line: ${response.status}`
                )

                formSuccess = true // Progress to the success page anyway
            }
        } catch (err) {
            // Sentry.captureMessage(
            //     `Could not connect to Office Line: ${err}`,
            //     'error'
            // )
            console.error(`Could not connect to Office Line: ${err}`)

            formSuccess = true // Progress to the success page anyway
        }
    }

    const sendToFirebase = async (options) => {
        try {
            await firebase
                .firestore()
                .collection(options.firebaseCollection)
                .add(options.firebaseData)
        } catch (err) {
            // Sentry.captureMessage(
            //     `Could not connect to Firebase: ${err}`,
            //     'error'
            // )
            console.error(`Could not connect to Firebase: ${err}`)
        }
    }

    const sendToMdeg = async (options) => {

        if (options.mdegValue !== "N") {

            const everflowUrl = 'https://www.wqm0gtrk.com/?nid=2818&transaction_id='+options.mdegValue+'&verification_token=BmxKD2ncZ3PjMM7gp0gnfmtCKkZvyZ';

            try {
                const response = await fetch(everflowUrl)

                if (!response.ok) {
                    console.error('Error connecting to MDEG ef:', response.status)
                  }

            } catch (err) {
                console.error('Could not connect to MDEG ef:', err)
            }
            
        }

    }

    const sendData = async (options) => {
        try {
            await sendToFirebase(options)
            await sendToOfficeLine(options)
            await sendToMdeg(options)

            if (formSuccess) {
                // Everything is complete
                navigate(options.successPage)
            }
        } catch (err) {
            // Sentry.captureMessage(
            //     'There was a critical error with the form',
            //     'critical'
            // )
            console.error('There was a critical error with the form')
        }
    }

    sendData(options)
}

export default submitLead
